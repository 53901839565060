import { useRef, useEffect } from 'react';
import type { Food as FoodType } from '../types/food';
import { gsap } from 'gsap';

type Args = {
  data: FoodType;
  index: number;
  remove(index: number): void;
}

function Food({data, remove, index}: Args) {
  const container = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (data.added) {
      gsap.to(container.current, { opacity: 1, y: 0, ease: 'power3.out', duration: .4 });
    } else {
      gsap.to(container.current, { opacity: 1, y: 0, ease: 'power3.out', duration: 0 });
    }
  }, [data.added]);

  return (
    <main ref={container} className="translate-y-[100px] opacity-0 flex justify-between bg-base px-[1.2em] py-[.8em] border-2 border-black rounded-lg">
      <div>
        <h4>{data.name}</h4>
        <p>x {data.quantity}</p>
      </div>
      <button onClick={() => remove(index)}>
        <img className="w-[1.8em]" src={require('../img/trash.svg').default} alt="trash"/>
      </button>
    </main>
  );
}

export default Food;