import { useState, useRef, FormEvent, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import type { Drink as DrinkType } from '../types/drink';
import type { Reservation } from '../types/reservation';
import { gsap } from 'gsap';

import Back from '../components/back';
import Button from '../components/button';
import Drink from '../components/drink';

function DrinkPage() {
  const navigate = useNavigate();
  const [drinks, setDrinks] = useState<DrinkType[]>([]);
  const modal = useRef<HTMLDivElement>(null);
  const name = useRef<HTMLInputElement>(null);
  const quantity = useRef<HTMLInputElement>(null);
  const skip = useRef<HTMLButtonElement>(null);

  const openModal = () => {
    modal.current?.classList.remove('hidden');
    gsap.to(modal.current, { y: 0, duration: .4, ease: 'power2.out' });
  }

  const closeModal = (callback?: () => void) => {
    gsap.to(modal.current, { y: '100%', duration: .4, ease: 'power2.out', onComplete() {
      modal.current?.classList.add('hidden');
      if (callback) callback();
    }});
  }

  const addDrink = (e: FormEvent) => {
    e.preventDefault();
    const callback = () => {      
      const arr = [...drinks];
      arr.push({name: name.current!.value, quantity: parseInt(quantity.current!.value), added: true});
      setDrinks(arr);
      name.current!.value = '';
      quantity.current!.value = '';
    }
    closeModal(callback);
  }

  const removeDrink = (index: number) => {
    const arr = [...drinks];
    arr.splice(index, 1);
    setDrinks(arr);
  }

  const mouseEnter = () => {
    gsap.to(skip.current, { y: '5px', x: '5px', ease: 'power3.out', boxShadow: '0px 0px 0 black', duration: .2 });
  }

  const mouseLeave = () => {
    gsap.to(skip.current, { y: 0, x: 0, ease: 'power3.out', boxShadow: '5px 5px 0 black', duration: .2 });
  }

  const skipProcess = () => {
    const reservation: Reservation = JSON.parse(localStorage.getItem('reservation')!);
    reservation.drinks = [];
    localStorage.setItem('reservation', JSON.stringify(reservation));
    navigate('/remark');
  }

  const proceed = () => {
    const reservation: Reservation = JSON.parse(localStorage.getItem('reservation')!);
    reservation.drinks = drinks;
    localStorage.setItem('reservation', JSON.stringify(reservation));
    navigate('/remark');
  }

  useEffect(() => {
    const reservation: Reservation = JSON.parse(localStorage.getItem('reservation')!);
    if (reservation) {
      if (reservation.drinks) {
        setDrinks(reservation.drinks.map(val => ({...val, added: false})));
      }
    }
    window.scrollTo(0, 0);
  }, []);

  return (
    <main className="container">
      <section className="container-wrapper">
        <Back/>

        <div className="flex justify-between mt-2 items-center">
          <h1 className="title">Drink</h1>
          <button onClick={openModal} className="flex items-center space-x-[.5em] bg-base py-[.3em] px-[.5em] border-2 border-black rounded-lg">
            <img src={require('../img/plus.svg').default} alt="plus"/>
            <h4>Add drink</h4>
          </button>
        </div>

        {(() => {
          if (drinks.length > 0) {
            return (
              <div className="space-y-[1.5em] mt-2">
                {drinks.map((val, i) => {
                  return <Drink index={i} key={i} data={val} remove={i => removeDrink(i)}/>
                })}

                <Button text="proceed" onClick={proceed}/>
              </div>
            )
            
          } else {
            return (
              <div className="flex flex-col justify-center items-center mt-5">
                <img className="w-2/6" src={require('../img/drink.svg').default} alt="food"/>
                <h2 className="font-semibold mt-[.5em] text-lg">No drink added</h2>
                <p>Add one or skip</p>
                <p className="text-center mt-[.5em]">Note: SILA PILIH MINUMAN YANG ADA DI DALAM MENU SAHAJA TERIMA KASIH</p>
                <button onClick={skipProcess} onMouseEnter={mouseEnter} onMouseLeave={mouseLeave} ref={skip} className="bg-accent w-[150px] mt-1 py-[.7rem] rounded-lg border-2 border-black shadow-[5px_5px_0_black]">Skip</button>
              </div>
            )
          }
        })()}
        
      </section>

      <section ref={modal} className="fixed translate-y-[100%] hidden top-0 left-0 bg-gradient-to-b from-base to-orange w-full h-screen p-3">

        <section className="max-w-[700px] mx-auto">

          <div className="flex justify-between items-center">
            <h1 className="title">Add drink</h1>
            <button onClick={() => closeModal()}>
              <img src={require('../img/close.svg').default} alt="close"/>
            </button>
          </div>

          <form onSubmit={addDrink} className="space-y-[1.5em] mt-2">

            <div>
              <label className="label">Drink</label>
              <input required ref={name} className="input" type="text"/>
            </div>

            <div>
              <label className="label">Quantity</label>
              <input inputMode="numeric" required ref={quantity} className="input" type="number"/>
            </div>

            <Button text="add drink"/>

          </form>

        </section>

      </section>
    </main>
  );
}

export default DrinkPage;