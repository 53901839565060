import { useRef, FormEvent, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import Back from '../components/back';
import Button from '../components/button';
import type { Reservation } from '../types/reservation';

function Remark() {
  const navigate = useNavigate();
  const remark = useRef<HTMLTextAreaElement>(null);

  const proceed = (e: FormEvent) => {
    e.preventDefault();
    const reservation: Reservation = JSON.parse(localStorage.getItem('reservation')!);
    reservation.remark = remark.current!.value;
    localStorage.setItem('reservation', JSON.stringify(reservation));
    navigate('/preview');
  }

  useEffect(() => {
    const reservation: Reservation = JSON.parse(localStorage.getItem('reservation')!);
    if (reservation) {
      if (reservation.remark) remark.current!.value = reservation.remark;
    }
    window.scrollTo(0, 0);
  }, []);

  return (
    <main className="container">

      <section className="container-wrapper">
        <Back/>

        <h1 className="title mt-2">Remark</h1>
        <p className="mt-[.3em]">This is optional</p>

        <form onSubmit={proceed} className="space-y-[1.5em] mt-[1.5em]">

          <div>
            <label className="label">Remark</label>
            <textarea ref={remark} className="textarea"/>
          </div>

          <Button text="preview details"/>

        </form>

      </section>

    </main>
  );
}

export default Remark;
