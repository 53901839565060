import { useRef, FormEvent, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import Back from './components/back';
import Button from './components/button';
import type { Reservation } from './types/reservation';

function App() {
  const navigate = useNavigate();
  const name = useRef<HTMLInputElement>(null);
  const contactno = useRef<HTMLInputElement>(null);
  const totalPax = useRef<HTMLInputElement>(null);
  const date = useRef<HTMLInputElement>(null);
  const time = useRef<HTMLInputElement>(null);

  const proceed = (e: FormEvent) => {
    e.preventDefault();
    const data: Reservation = {
      name: name.current!.value,
      contact_no: contactno.current!.value,
      total_pax: parseInt(totalPax.current!.value),
      date: date.current!.value,
      time: time.current!.value
    };
    localStorage.setItem('reservation', JSON.stringify(data));
    navigate('/food');
  }

  useEffect(() => {
    const data = localStorage.getItem('reservation');
    if (data) {
      const reservation: Reservation = JSON.parse(data);
      name.current!.value = reservation.name;
      contactno.current!.value = reservation.contact_no;
      totalPax.current!.value = reservation.total_pax.toString();
      date.current!.value = reservation.date;
      time.current!.value = reservation.time;
    }
  }, []);

  return (
    <main className="container">

      <section className="container-wrapper">
        <Back home={true}/>

        <img className="w-2/6 mx-auto mt-1" src={require('./img/logo.png')} alt="logo"/>

        <h1 className="title text-center mt-2">Reservation enquiry</h1>

        <form onSubmit={proceed} className="space-y-[1.5em] mt-[1.5em]">

          <div>
            <label className="label">Name</label>
            <input required ref={name} type="text" className="input"/>
          </div>

          <div>
            <label className="label">Contact no</label>
            <input required ref={contactno} inputMode="numeric" type="number" className="input"/>
          </div>

          <div>
            <label className="label">No of pax</label>
            <input required ref={totalPax} inputMode="numeric" type="number" className="input"/>
          </div>

          <div>
            <label className="label">Date</label>
            <input required ref={date} type="date" className="input"/>
          </div>

          <div>
            <label className="label">time</label>
            <input required ref={time} type="time" className="input"/>
          </div>

          <Button text="proceed"/>

        </form>

      </section>

    </main>
  );
}

export default App;
