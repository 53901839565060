import { useNavigate } from 'react-router-dom';

type args = {
  home?: boolean;
}

function Back({home = false}: args) {
  const navigate = useNavigate();

  const action = () => {
    if (home) {
      window.open('https://fullstopcoffeecafe.com', '_self');
      return;
    }
    navigate(-1);
  }

  return (
    <button onClick={action} type="button" className="flex items-center">
      <img className="rotate-180 w-1" src={require('../img/chevron.svg').default} alt="chevron"/>
      <p className="ml-[.5em]">Back</p>
    </button>
  );
}

export default Back;