import { useRef, useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import Back from '../components/back';
import type { Food } from '../types/food';
import type { Drink } from '../types/drink';
import type { Reservation } from '../types/reservation';
import { gsap } from 'gsap';
import moment from 'moment';

function Preview() {
  const navigate = useNavigate();
  const [name, setName] = useState<string>('');
  const [contactNo, setContactNo] = useState<string>('');
  const [totalPax, setTotalPax] = useState<number>(0);
  const [date, setDate] = useState<string>('');
  const [time, setTime] = useState<string>('');
  const [remark, setRemark] = useState<string>('');
  const [foods, setFoods] = useState<Food[]>([]);
  const [drinks, setDrinks] = useState<Drink[]>([]);
  const button = useRef<HTMLButtonElement>(null);
  const chevron = useRef<HTMLImageElement>(null);

  useEffect(() => {
    const reservation: Reservation = JSON.parse(localStorage.getItem('reservation')!);
    if (reservation) {
      setName(reservation.name);
      setContactNo(reservation.contact_no);
      setTotalPax(reservation.total_pax);
      setDate(moment(reservation.date).format('D MMM YYYY'));
      setFoods(reservation.foods!);
      setDrinks(reservation.drinks!);
      setRemark(reservation.remark ? reservation.remark : '');
  
      const hour = reservation.time.match(/^\d{2}/);
      const minute = reservation.time.match(/\d{2}$/);
      if (hour) {
        if (hour.length > 0) {
          const time = moment().hour(parseInt(hour![0])).minute(parseInt(minute![0]));
          setTime(time.format('h:mm a'));
        }
      }
  
      gsap.timeline({repeat: -1, defaults: { ease: 'none', duration: .4 }})
        .to(chevron.current, { x: '8px' })
        .to(chevron.current, { x: '0px' });
    }
    window.scrollTo(0, 0);
  }, []);

  const mouseEnter = () => {
    gsap.to(button.current, { y: '5px', x: '5px', ease: 'power3.out', boxShadow: '0px 0px 0 black', duration: .2 });
  }

  const mouseLeave = () => {
    gsap.to(button.current, { y: 0, x: 0, ease: 'power3.out', boxShadow: '5px 5px 0 black', duration: .2 });
  }

  const whatsapp = () => {
    const foodList = foods.map((val, i) => `${val.name} x ${val.quantity}${i < (foods.length - 1) ? '\n' : ''}`);
    const drinkList = drinks.length > 0 ? drinks.map((val, i) => `${val.name} x ${val.quantity}${i < (drinks.length - 1) ? '\n' : ''}`) : null;

    const drinkPrint = () => {
      if (drinkList) {
        return `*DRINKS*
        
${drinkList.join('')}`;
      }
      return '*No drink*';
    };
    const message = ` *NEW RESERVATION*

*RESERVATION DETAILS*

NAME: ${name}
CONTACT NO: ${contactNo}
TOTAL OF PAX: ${totalPax}
DATE: ${date}
TIME: ${time}

*FOODS*

${foodList.join('')}

${drinkPrint()}

REMARK: ${remark === '' ? 'None' : remark}`;

    const url = `https://wa.me/60196507822?text=${encodeURI(message)}`;
    window.open(url);
    localStorage.removeItem('reservation');
    navigate('/');
  }

  return (
    <main className="container">

      <section className="container-wrapper">
        <Back/>

        <h1 className="title mt-2">Preview details</h1>

        <section className="mt-2 mb-2">

          <div>
            <h4 className="font-bold">Name</h4>
            <p className="mt-[.2em]">{name}</p>
          </div>

          <div className="mt-1 flex">
            <div className="w-1/2">
              <h4 className="font-bold">Contact no</h4>
              <p className="mt-[.2em]">{contactNo}</p>
            </div>

            <div className="w-1/2">
              <h4 className="font-bold">Total pax</h4>
              <p className="mt-[.2em]">{totalPax}</p>
            </div>
          </div>

          <div className="flex mt-1">
            <div className="w-1/2">
              <h4 className="font-bold">Date</h4>
              <p className="mt-[.2em]">{date}</p>
            </div>

            <div className="w-1/2">
              <h4 className="font-bold">Time</h4>
              <p className="mt-[.2em]">{time}</p>
            </div>
          </div>


          <div className="mt-[2.5em]">
            <h4 className="font-bold">Food</h4>
            <div className="space-y-[1.2em] mt-1">
              {foods.map((val, i) => {
                return (
                  <div key={i} className="flex justify-between px-1 py-[.7em] bg-base rounded-lg border-2 border-black">
                    <h4 className="font-semibold">{val.name}</h4>
                    <h4 className="font-semibold">X {val.quantity}</h4>
                  </div>
                )
              })}
            </div>
          </div>
          
          {(() => {
            if (drinks.length > 0) {
              return (
                <div className="mt-[2.5em]">
                  <h4 className="font-bold">Drinks</h4>
                  <div className="space-y-[1.2em] mt-1">
                    {drinks.map((val, i) => {
                      return (
                        <div key={i} className="flex justify-between px-1 py-[.7em] bg-base rounded-lg border-2 border-black">
                          <h4 className="font-semibold">{val.name}</h4>
                          <h4 className="font-semibold">X {val.quantity}</h4>
                        </div>
                      )
                    })}
                  </div>
                </div>
              );
            }
          })()}

          {(() => {
            if (remark) {
              return (
                <div className="mt-2">
                  <h4 className="font-bold">Remark</h4>
                  <p className="mt-[.2em]">{remark}</p>
                </div>
              )
            }
          })()}

        </section>

        <button onClick={whatsapp} onMouseEnter={mouseEnter} onMouseLeave={mouseLeave} ref={button} className="bg-accent w-full py-[.8rem] px-1 rounded-lg border-2 border-black flex justify-between items-center shadow-[5px_5px_0_black]">
          <div className="flex items-center">
            <img className="w-[1.5em]" src={require('../img/whatsapp.svg').default} alt="whatsapp"/>
            <p className="font-semibold uppercase ml-[.5em]">Send whatsapp</p>
          </div>
          <img ref={chevron} className="w-[1.1em]" src={require('../img/chevron.svg').default} alt="chevron"/>
        </button>

      </section>

    </main>
  );
}

export default Preview;
