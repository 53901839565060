import { useEffect, useRef } from 'react';
import { gsap } from 'gsap';

type Args = {
  text: string;
  onClick?(): void;
}

function Button({text, onClick} : Args) {
  const button = useRef<HTMLButtonElement>(null);
  const chevron = useRef<HTMLImageElement>(null);

  const mouseEnter = () => {
    gsap.to(button.current, { y: '5px', x: '5px', ease: 'power3.out', boxShadow: '0px 0px 0 black', duration: .2 });
  }

  const mouseLeave = () => {
    gsap.to(button.current, { y: 0, x: 0, ease: 'power3.out', boxShadow: '5px 5px 0 black', duration: .2 });
  }

  const eventListener = () => {
    onClick!();
  }

  useEffect(() => {
    gsap.timeline({repeat: -1, defaults: { ease: 'none', duration: .4 }})
      .to(chevron.current, { x: '8px' })
      .to(chevron.current, { x: '0px' });
  }, []);

  useEffect(() => {
    if (onClick) {
      button.current?.addEventListener('click', eventListener);
    }

    return () => {
      if (onClick) button.current?.removeEventListener('click', eventListener);
    };
  }, [onClick]);

  return (
    <button onMouseEnter={mouseEnter} onMouseLeave={mouseLeave} ref={button} className="bg-accent w-full py-[.8rem] px-1 rounded-lg border-2 border-black flex justify-between items-center shadow-[5px_5px_0_black]">
      <p className="font-semibold uppercase">{text}</p>
      <img ref={chevron} className="w-[1.1em]" src={require('../img/chevron.svg').default} alt="chevron"/>
    </button>
  );
}

export default Button;